import { getParagraphClasses } from "./style"
import type { ParagraphProps } from "./types"

export const Paragraph = ({ size = 1, children, className, testId, style: _style, ...rest }: ParagraphProps) => {
  return (
    <p data-testid={testId ?? `paragraph-${size}`} className={getParagraphClasses({ size, className })} {...rest}>
      {children}
    </p>
  )
}
