export type DeepRequired<Type> = {
  [Key in keyof Type]-?: DeepRequired<NonNullable<Type[Key]>>
}

export type DeepWithoutOptionals<Type> = {
  [Key in keyof Type]-?: DeepWithoutOptionals<Exclude<Type[Key], undefined>>
}

export type MakeRequired<TObject, TKeys extends keyof TObject> = Omit<TObject, TKeys> & {
  [Key in TKeys]-?: NonNullable<TObject[Key]>
}

export type MakeOptional<TObject, TKeys extends keyof TObject> = Omit<TObject, TKeys> & {
  [Key in TKeys]?: NonNullable<TObject[Key]>
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export type AllOrNone<T> = T | { [K in keyof T]?: never }

export type Nullable<T> = T | null | undefined

export type ListQuery<TNode = unknown> = { edges?: Nullable<Nullable<{ node?: Nullable<TNode> }>[]> }

export type NonNullListQueryNode<TListQuery extends Nullable<{ edges?: Nullable<Nullable<{ node?: unknown }>[]> }>> =
  NonNullable<NonNullable<NonNullable<NonNullable<TListQuery>["edges"]>[number]>["node"]>

export type BasicObject<T = unknown> = Record<string, T | undefined>

export enum Product {
  LuggageStorage = "Luggage Storage",
  PackageAcceptance = "Package Acceptance",
}

export enum App {
  Customer = "customer",
  Partner = "partner",
  Packages = "packages",
}

export type ValueOf<T extends Record<string, unknown>> = T[keyof T]
