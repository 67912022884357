import type { CommonTypographyProps } from "./types"

export const getLineClampClass = (numberOfLines: CommonTypographyProps["numberOfLines"]): string | undefined =>
  numberOfLines &&
  {
    1: "line-clamp-1",
    2: "line-clamp-2",
    3: "line-clamp-3",
    4: "line-clamp-4",
  }[numberOfLines]
