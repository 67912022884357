import React from "react"

import type { UseNavbarOptions } from "./useNavbar"
import { useNavbar } from "./useNavbar"
import { Navbar as BaseNavbar } from "@/components/Generic/Navbar/Navbar"

export type NavbarProps = UseNavbarOptions

export const Navbar = (props: NavbarProps) => {
  const navbarProps = useNavbar(props)

  return <BaseNavbar {...navbarProps} />
}
