import classNames from "classnames"
import React from "react"

import { Link } from "../Link/Link"

import ArrowRightIcon from "@bounce/assets/icons/ArrowRight.svg"

export type Breadcrumb = {
  id: string
  name: string
  href: string | null
}

export type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[]
  className?: string
  itemClassName?: string
  linkClassName?: string
}

export const Breadcrumbs = ({ breadcrumbs, className, itemClassName, linkClassName }: BreadcrumbsProps) => (
  <nav className={classNames(className, "breadcrumb")}>
    {breadcrumbs.map(({ id, name, href }, i) => {
      if (i === breadcrumbs.length - 1) {
        return (
          <span key={id} className={classNames(itemClassName, "breadcrumb__item")}>
            {name}
          </span>
        )
      }

      return (
        <React.Fragment key={id}>
          {href ? (
            <Link href={href} className={classNames(linkClassName, itemClassName, "breadcrumb__item")}>
              {name}
            </Link>
          ) : (
            <span className={classNames(itemClassName, "breadcrumb__item")}>{name}</span>
          )}
          <ArrowRightIcon className="breadcrumb__icon" />
        </React.Fragment>
      )
    })}
  </nav>
)
