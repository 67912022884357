import React, { useEffect } from "react"

import { Analytics, CouponEvents } from "@bounce/analytics"
import { useTranslation } from "@bounce/i18n"
import { AlertBanner } from "@bounce/web-components"

export type PromotionBannerProps = {
  coupon?: string
}

export const PromotionBanner = ({ coupon }: PromotionBannerProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    Analytics.track(CouponEvents.CouponBannerShown, {
      coupon,
    })
  }, [coupon])

  return (
    <AlertBanner
      type="SUCCESS"
      messageClassName="text-center whitespace-pre-line sm:whitespace-normal"
      viewClassName="justify-center rounded-none px-5 py-2.5"
      message={t("cmp.promotionBannerNext.message", "Promotion applied.\nYou can view your discount at checkout.")}
    />
  )
}
