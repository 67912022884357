import { useRouter } from "next/router"

import { Analytics, useAnonymousId } from "@bounce/analytics"
import { useIsClient } from "@bounce/web-components"

import { InteractionEvents } from "../libs/analytics/events"
import { getAppStoreUrl } from "../utils/getAppStoreUrl"
import type { GetAppStoreUrlOptions } from "../utils/getAppStoreUrl"

export type UseAppStoreUrlOptions = {
  eventProps: {
    location:
      | "HowItWorks"
      | "AppStickyBanner"
      | "AppBannerLuggageStorageSecondary"
      | "AppBannerLuggageStorage"
      | "AppBannerPackages"
      | "AppBannerVideoDemo"
      | "PackagesAppBannerVideoDemoV2"
      | "FooterAppBanner"
      | "AppDownloadHero"
    cta?: string
  }
} & GetAppStoreUrlOptions

/**
 * Get the app store url, and a onClick function to track the interactions
 * @example
 * const { href, onClick } = getAppStoreUrl({
 *  app: "luggageStorage",
 *  eventProps: { location: "AppBannerLuggageStorage" },
 * })
 *
 * return (<a href={href} onClick={onClick}>Download the app</a>)
 */
export const useAppStoreUrl = ({ app, eventProps, campaignName }: UseAppStoreUrlOptions) => {
  const router = useRouter()
  const isClient = useIsClient()
  const { anonymousId } = useAnonymousId()

  // slugify the route to use it as a campaign name eg: /city/[city]/[poi] => city-city-poi
  const routeAsSlug = router.route.replaceAll("/", "-").replaceAll("[", "").replaceAll("]", "")
  const defaultCampaign = `marketing-${routeAsSlug}-${eventProps.location}`
  const _campaignName = campaignName || defaultCampaign

  const href = isClient ? getAppStoreUrl({ app, campaignName: _campaignName, userId: anonymousId }) : "#"

  const onClick = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()

    Analytics.track(InteractionEvents.DownloadTheAppPressed, {
      pageName: router.route,
      pagePath: router.asPath,
      ...eventProps,
    })

    location.assign(ev.currentTarget.href)
  }

  return { href, onClick }
}
