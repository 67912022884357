import React from "react"

import type { BreadcrumbsProps as BaseBreadcrumbsProps } from "@bounce/web-components"
import { Breadcrumbs as BaseBreadcrumbs } from "@bounce/web-components"

import { BreadcrumbListStructuredData } from "../StructuredData/StructuredData"

export type BreadcrumbsProps = BaseBreadcrumbsProps

export const Breadcrumbs = (props: BreadcrumbsProps) => (
  <>
    <BreadcrumbListStructuredData breadcrumbs={props.breadcrumbs} />
    <BaseBreadcrumbs {...props} />
  </>
)
