import { Paragraph } from "./Paragraph"
import type { ParagraphSize, ParagraphProps } from "./types"

const createVariant = (size: ParagraphSize) => {
  const ParagraphVariant: React.FC<Omit<ParagraphProps, "size">> = props => <Paragraph {...props} size={size} />
  ParagraphVariant.displayName = `Paragraph${size}`
  return ParagraphVariant
}

export const Paragraph1 = createVariant(1)
export const Paragraph2 = createVariant(2)
