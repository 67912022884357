import type { UnifiedDeepLinkData } from "react-native-appsflyer"

export type ResolvedDeepLinkData = UnifiedDeepLinkData["data"] & {
  cuid?: string
  auth_token?: string
  refresh_token?: string
  expiry?: string
}

export enum OpenAppStore {
  Always = "always",
  MobileOnly = "mobile_only",
  Never = "never",
}
